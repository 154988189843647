import { QUOTE_TOKEN_SYMBOL } from "~/lib/util";

export default function AddLiquidityExplanation() {
   return (
     <div className="flex flex-col space-y-4 rounded-xl lg:rounded-2xl bg-primary-800 p-8 text-white lg:w-96">
       <p>
         DefiPlaza is an AMM engineered to protect the liquidity provider
         against the risks of Impermanent Loss.
       </p>
       <p className="text-primary-100">
         Our innovative CALM algorithm focuses on trade quality over trade
         quantity to make providing liquidity sustainably profitable.
       </p>
       <p className="text-primary-100">
         <a href="https://defiplaza.net" target="_blank" className="underline">
           Learn more
         </a>
       </p>

       <div className="hidden lg:block">
         <p className="pt-5">How does adding liquidity work?</p>
         <p className="text-primary-100">
           To add liquidity to DefiPlaza you first pick a pool from the overview
           on the left. If the pool does not exist, you can create it.
         </p>
         <p className="text-primary-100">
           Adding liquidity to a pool is single-sided. Meaning you can choose to
           either provide the base token, or {QUOTE_TOKEN_SYMBOL}. There is no
           requirement to add both.
         </p>
         <p className="text-primary-100">
           After you provide liquidity, the LP Tokens can be found in the Radix
           Wallet under Pool Units.
         </p>
         <p className="text-primary-100">
           Although DefiPlaza is designed to minimize Impermanent Loss, some
           amount of Impermanent Loss may still occur. Please do your own
           research and understand the risks of Impermanent Loss before
           providing liquidity to DefiPlaza.
         </p>
       </div>
     </div>
   );
}
